import {default as ApiMakerConfig} from "@kaspernj/api-maker/src/config"
import {createRoot} from "react-dom/client"
import {LoadingBlocker} from "components/layout/loading-blocker"
import ModelsResponseReader from "@kaspernj/api-maker/src/models-response-reader"
import {Container as Notifications} from "flash-notifications"
import Router from "@kaspernj/api-maker/src/router"
import SessionStatusUpdater from "@kaspernj/api-maker/src/session-status-updater.mjs"
import withPathname from "on-location-changed/src/with-path"

require("shared/devise")

if (window.currentUserData) {
  const modelsResponseReader = new ModelsResponseReader({response: window.currentUserData})
  const models = modelsResponseReader.models()

  window.currentUser = models[0]
}

SessionStatusUpdater.current().startTimeout()

const LayoutNotFoundPage = React.lazy(() => import(/* webpackChunkName: "not-found-page" */ "components/layout/not-found-page"))
const requireComponent = ({routeDefinition}) => React.lazy(() => import(/* webpackChunkName: "[request]" */ `my/routes/${routeDefinition.component}-route`))
const routeDefinitions = require("my/route-definitions.json")
const RouterWithLocation = withPathname(Router)

ApiMakerConfig.setRoutes(Routes)
ApiMakerConfig.setRouteDefinitions(routeDefinitions)

document.addEventListener("DOMContentLoaded", () => {
  const reactRoot = document.querySelector(".react-root")
  const root = createRoot(reactRoot)

  root.render(
    <>
      <LoadingBlocker />
      <Notifications />
      <RouterWithLocation
        history={AppHistory}
        notFoundComponent={LayoutNotFoundPage}
        requireComponent={requireComponent}
        routeDefinitions={routeDefinitions}
        routes={MyRoutes}
      />
    </>
  )
})
